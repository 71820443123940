import { get } from "lodash";
import { useState } from "react";
import { useQuery } from "react-query";
import {
  Button,
  Text,
  FormLabel,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Progress,
  Heading,
  Table,
  Tbody,
  Tr,
  Td,
} from "@chakra-ui/react";
import { useIndexFactorGroups, useMsrGroups } from "../../queries";
import { api } from "../../api";

export default function Review({
  event,
  msrEvent,
  selectedMsrSegments,
  selectedGroups,
  selectedPaxConfig,
  penaltySeconds,
  selectedSeries,
  close,
  importEvent,
  error,
}) {
  const handleNext = importEvent;

  console.log("event", event);
  console.log("selectedPaxConfig", selectedPaxConfig);

  let errorMsg;
  if (error && error.response) {
    errorMsg = get(error, "response.data.error", "unknown error");
  }

  const {
    isLoading: isPaxConfigsLoading,
    isError: isPaxConfigsError,
    data: paxConfigData,
  } = useIndexFactorGroups();

  let paxConfig = get(event, "index_factor_group", null);
  if (!paxConfig && paxConfigData) {
    paxConfig = paxConfigData.find((d) => d.id === selectedPaxConfig);
  }

  const {
    isLoading: isMsrGroupsLoading,
    isError: isMsrGroupsError,
    data: groups,
  } = useMsrGroups(
    msrEvent.id,
    selectedMsrSegments.map((s) => s.id)
  );

  const {
    isLoading: isSeriesLoading,
    isError: isSeriesError,
    data: allSeries,
  } = useQuery("active-series", api.eventSeries(), {
    refetchOnMount: true,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });

  const isLoading =
    isPaxConfigsLoading || isMsrGroupsLoading || isSeriesLoading;
  const isError = isPaxConfigsError || isMsrGroupsError || isSeriesError;

  const filteredGroups = groups.filter((g) =>
    selectedGroups.map((g) => g.id).includes(g.id)
  );

  const filteredSeries = allSeries.filter((series) =>
    selectedSeries.includes(series.id)
  );

  return (
    <ModalContent>
      <ModalHeader>Import MSR event</ModalHeader>

      {!isLoading && (
        <>
          <ModalBody>
            <FormLabel>Review your selections</FormLabel>
            <Table variant="simple">
              <Tbody>
                <Tr>
                  <Td>Event</Td>
                  <Td>
                    <Text>{msrEvent.name}</Text>
                    <Text>{msrEvent.start}</Text>
                  </Td>
                </Tr>
                <Tr>
                  <Td>Segments</Td>
                  <Td>
                    {selectedMsrSegments.map((s) => (
                      <Text>{s.name}</Text>
                    ))}
                  </Td>
                </Tr>
                <Tr>
                  <Td>Groups</Td>
                  <Td>
                    {filteredGroups.map((g) => (
                      <Text>
                        {g.longName} ({g.entryCount} entries)
                      </Text>
                    ))}
                  </Td>
                </Tr>
                <Tr>
                  <Td>PAX Configuration</Td>
                  <Td>{paxConfig.name}</Td>
                </Tr>
                <Tr>
                  <Td>Series</Td>
                  <Td>
                    {filteredSeries.map((s) => (
                      <Text>{s.name}</Text>
                    ))}
                  </Td>
                </Tr>
                <Tr>
                  <Td>Penalty Seconds</Td>
                  <Td>{penaltySeconds}</Td>
                </Tr>
              </Tbody>
            </Table>
          </ModalBody>
          <ModalFooter>
            <Button onClick={close} ml={3}>
              Back
            </Button>
            <Button colorScheme="blue" onClick={handleNext} ml={3}>
              Import
            </Button>
          </ModalFooter>
        </>
      )}
      {isLoading && (
        <ModalBody>
          {/* <Heading size="sm" pb={4}>
            Importing segments from MSR...
          </Heading> */}
          <Progress size="xs" isIndeterminate my={8} />
        </ModalBody>
      )}
      {errorMsg && (
        <ModalBody>
          <Heading size="sm" pb={4} color="red.500">
            Unable to import MSR event.
            <br />
            <br />
            {errorMsg}
          </Heading>
        </ModalBody>
      )}
    </ModalContent>
  );
}
