import { useRef, useState } from "react";
import {
  useToast,
  Box,
  Button,
  Card,
  CardBody,
  Flex,
  Text,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Badge,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
} from "@chakra-ui/react";
import { Link, useParams } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import Head from "./Head";
import Footer from "./Footer";
import { api } from "../api";
import NewEventButton from "./NewEventButton";
import LoadingScreen from "./LoadingScreen";
import Loading from "../components/ImportMsrEvent/Loading";
import ImportMsrEvent from "../components/ImportMsrEvent";
import EventsSidebar from "../screens/events/EventsSidebar";
import { useLoginOnUnauthorized, useMsrEvent } from "../hooks";
import Pagy from "./Pagy";

export default function EventActionsMenu({ event, refetchEvents }) {
  const toast = useToast();
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  // const [isReimportOpen, setIsReimportOpen] = useState(false);
  const [isMoreGroupsOpen, setIsMoreGroupsOpen] = useState(false);
  const [isPublishPreliminaryResultsOpen, setIsPublishPreliminaryResultsOpen] =
    useState(false);
  const [isPublishFinalResultsOpen, setIsPublishFinalResultsOpen] =
    useState(false);
  const [isRepublishResultsOpen, setIsRepublishResultsOpen] = useState(false);
  const [isUnarchiveOpen, setIsUnarchiveOpen] = useState(false);
  const cancelRef = useRef();
  const cancelReimportRef = useRef();
  const cancelMoreGroupsRef = useRef();
  const cancelPublishPreliminaryResultsRef = useRef();
  const cancelPublishFinalResultsRef = useRef();
  const cancelRepublishResultsRef = useRef();
  const cancelUnarchiveRef = useRef();
  const deleteEventMutation = useMutation(() => api.deleteEvent(event.id), {
    onSuccess: () => {
      refetchEvents();
      setIsDeleteOpen(false);
      toast({
        title: "Success",
        description: `Event ${event.name} successfully deleted`,
        status: "success",
        duration: 6000,
        isClosable: true,
      });
    },
    onError: () => {
      toast({
        title: "Error",
        description: `Error deleting event ${event.name}`,
        status: "error",
        duration: 6000,
        isClosable: true,
      });
    },
  });
  const reimportEventMutation = useMutation(
    () =>
      api.reimportMsrEvent(
        event.msr_id,
        event.segments.map((s) => s.msr_id)
      ),
    {
      onSuccess: () => {
        refetchEvents();
        setIsMoreGroupsOpen(false);
        toast({
          title: "Success",
          description: `Event ${event.name} successfully re-imported`,
          status: "success",
          duration: 6000,
          isClosable: true,
        });
      },
      onError: () => {
        toast({
          title: "Error",
          description: `Error re-importing event ${event.name}`,
          status: "error",
          duration: 6000,
          isClosable: true,
        });
      },
    }
  );
  const publishPreliminaryResultsMutation = useMutation(
    () => api.completeEvent(event.id),
    {
      onSuccess: () => {
        refetchEvents();
        setIsPublishPreliminaryResultsOpen(false);
        toast({
          title: "Success",
          description: "Preliminary events successfully published",
          status: "success",
          duration: 6000,
          isClosable: true,
        });
      },
      onError: () => {
        toast({
          title: "Error",
          description: "Error publishing preliminary results",
          status: "error",
          duration: 6000,
          isClosable: true,
        });
      },
    }
  );
  const publishFinalResultsMutation = useMutation(
    () => api.archiveEvent(event.id),
    {
      onSuccess: () => {
        refetchEvents();
        setIsPublishFinalResultsOpen(false);
        toast({
          title: "Success",
          description: "Final events successfully published",
          status: "success",
          duration: 6000,
          isClosable: true,
        });
      },
      onError: () => {
        toast({
          title: "Error",
          description: "Error publishing final results",
          status: "error",
          duration: 6000,
          isClosable: true,
        });
      },
    }
  );
  const republishResultsMutation = useMutation(
    () => api.eventRepublishResults(event.id),
    {
      onSuccess: () => {
        setIsRepublishResultsOpen(false);
        toast({
          title: "Success",
          description: "Final events successfully republished",
          status: "success",
          duration: 6000,
          isClosable: true,
        });
      },
      onError: () => {
        toast({
          title: "Error",
          description: "Error republishing results",
          status: "error",
          duration: 6000,
          isClosable: true,
        });
      },
    }
  );
  const unarchiveEventMutation = useMutation(
    () => api.eventUnarchive(event.id),
    {
      onSuccess: () => {
        refetchEvents();
        setIsUnarchiveOpen(false);
        toast({
          title: "Success",
          description: "Event unarchived successfully",
          status: "success",
          duration: 6000,
          isClosable: true,
        });
      },
      onError: () => {
        toast({
          title: "Error",
          description: "Error unarchiving event",
          status: "error",
          duration: 6000,
          isClosable: true,
        });
      },
    }
  );

  const { data: msrEvent } = useMsrEvent(event.msr_id);

  const onDelete = () => deleteEventMutation.mutate();
  // const onReimport = () => reimportEventMutation.mutate();
  const onMoreGroups = () => reimportEventMutation.mutate();
  const onPublishPreliminaryResults = () =>
    publishPreliminaryResultsMutation.mutate();
  const onPublishFinalResults = () => publishFinalResultsMutation.mutate();
  const onRepublishResults = () => republishResultsMutation.mutate();
  const onUnarchive = () => unarchiveEventMutation.mutate();
  const handleDelete = () => setIsDeleteOpen(true);
  // const handleReimport = () => setIsReimportOpen(true);
  const handleMoreGroups = () => setIsMoreGroupsOpen(true);
  const handlePublishPreliminaryResults = () =>
    setIsPublishPreliminaryResultsOpen(true);
  const handlePublishFinalResults = () => setIsPublishFinalResultsOpen(true);
  const handleRepublishResults = () => setIsRepublishResultsOpen(true);
  const handleUnarchive = () => setIsUnarchiveOpen(true);

  return (
    <>
      <Menu>
        <MenuButton>
          <strong>...</strong>
        </MenuButton>
        <MenuList>
          {event.state === "created" && event.msr_id !== null && (
            <MenuItem onClick={handleMoreGroups}>Import more groups</MenuItem>
          )}
          {event.state === "created" && (
            <MenuItem onClick={handlePublishPreliminaryResults}>
              Publish preliminary results
            </MenuItem>
          )}
          {event.state === "completed" && (
            <MenuItem onClick={handlePublishFinalResults}>
              Publish final results
            </MenuItem>
          )}
          {(event.state === "completed" || event.state === "archived") && (
            <MenuItem onClick={handleRepublishResults}>
              Republish results
            </MenuItem>
          )}
          {event.state === "archived" && (
            <MenuItem onClick={handleUnarchive}>Unarchive event</MenuItem>
          )}
          {/* {event.state === "created" && event.msr_id !== null && (
            <MenuItem onClick={handleReimport}>Re-import from MSR</MenuItem>
          )} */}
          <MenuItem onClick={handleDelete}>Delete event</MenuItem>
        </MenuList>
      </Menu>
      <AlertDialog
        isOpen={isDeleteOpen}
        leastDestructiveRef={cancelRef}
        onClose={() => setIsDeleteOpen(false)}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete event - {event.name}
            </AlertDialogHeader>
            <AlertDialogBody>
              Are you sure?{" "}
              <strong>
                This will delete all event data including run times
              </strong>{" "}
              You cannot undo this action.
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={() => setIsDeleteOpen(false)}>
                Cancel
              </Button>
              <Button colorScheme="red" onClick={onDelete} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      {/* <AlertDialog
        isOpen={isReimportOpen}
        leastDestructiveRef={cancelReimportRef}
        onClose={() => setIsReimportOpen(false)}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Re-import {event.name}
            </AlertDialogHeader>
            {reimportEventMutation.isLoading ? (
              <AlertDialogBody>
                <Loading />
              </AlertDialogBody>
            ) : (
              <AlertDialogBody>
                Are you sure?{" "}
                <strong>
                  This will delete all event data including run times.
                </strong>{" "}
                You cannot undo this action.
              </AlertDialogBody>
            )}
            <AlertDialogFooter>
              <Button
                disabled={reimportEventMutation.isLoading}
                ref={cancelPublishPreliminaryResultsRef}
                onClick={() => setIsReimportOpen(false)}
              >
                Cancel
              </Button>
              <Button
                colorScheme="red"
                onClick={onReimport}
                ml={3}
                disabled={reimportEventMutation.isLoading}
              >
                Re-import
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog> */}
      <AlertDialog
        isOpen={isPublishPreliminaryResultsOpen}
        leastDestructiveRef={cancelPublishPreliminaryResultsRef}
        onClose={() => setIsPublishPreliminaryResultsOpen(false)}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Publish preliminary results
            </AlertDialogHeader>
            {publishPreliminaryResultsMutation.isLoading ? (
              <AlertDialogBody>
                <Loading />
              </AlertDialogBody>
            ) : (
              <AlertDialogBody>
                Are you sure you want to publish preliminary results?
              </AlertDialogBody>
            )}
            <AlertDialogFooter>
              <Button
                disabled={publishPreliminaryResultsMutation.isLoading}
                ref={cancelPublishPreliminaryResultsRef}
                onClick={() => setIsPublishPreliminaryResultsOpen(false)}
              >
                Cancel
              </Button>
              <Button
                colorScheme="red"
                onClick={onPublishPreliminaryResults}
                ml={3}
                disabled={publishPreliminaryResultsMutation.isLoading}
              >
                Publish
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      <AlertDialog
        isOpen={isPublishFinalResultsOpen}
        leastDestructiveRef={cancelPublishFinalResultsRef}
        onClose={() => setIsPublishFinalResultsOpen(false)}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Publish final results
            </AlertDialogHeader>
            {publishFinalResultsMutation.isLoading ? (
              <AlertDialogBody>
                <Loading />
              </AlertDialogBody>
            ) : (
              <AlertDialogBody>
                Are you sure you want to publish final results?
              </AlertDialogBody>
            )}
            <AlertDialogFooter>
              <Button
                disabled={publishFinalResultsMutation.isLoading}
                ref={cancelPublishFinalResultsRef}
                onClick={() => setIsPublishFinalResultsOpen(false)}
              >
                Cancel
              </Button>
              <Button
                colorScheme="red"
                onClick={onPublishFinalResults}
                ml={3}
                disabled={publishFinalResultsMutation.isLoading}
              >
                Publish
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      <AlertDialog
        isOpen={isRepublishResultsOpen}
        leastDestructiveRef={cancelRepublishResultsRef}
        onClose={() => setIsRepublishResultsOpen(false)}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Republish results
            </AlertDialogHeader>
            {republishResultsMutation.isLoading ? (
              <AlertDialogBody>
                <Loading />
              </AlertDialogBody>
            ) : (
              <AlertDialogBody>
                Are you sure you want to republish results?
              </AlertDialogBody>
            )}
            <AlertDialogFooter>
              <Button
                disabled={republishResultsMutation.isLoading}
                ref={cancelRepublishResultsRef}
                onClick={() => setIsRepublishResultsOpen(false)}
              >
                Cancel
              </Button>
              <Button
                colorScheme="red"
                onClick={onRepublishResults}
                ml={3}
                disabled={republishResultsMutation.isLoading}
              >
                Republish
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      <AlertDialog
        isOpen={isUnarchiveOpen}
        leastDestructiveRef={cancelUnarchiveRef}
        onClose={() => setIsUnarchiveOpen(false)}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Unarchive event
            </AlertDialogHeader>
            {unarchiveEventMutation.isLoading ? (
              <AlertDialogBody>
                <Loading />
              </AlertDialogBody>
            ) : (
              <AlertDialogBody>
                Are you sure you want to unarchive this event?
              </AlertDialogBody>
            )}
            <AlertDialogFooter>
              <Button
                disabled={unarchiveEventMutation.isLoading}
                ref={cancelUnarchiveRef}
                onClick={() => setIsUnarchiveOpen(false)}
              >
                Cancel
              </Button>
              <Button
                colorScheme="red"
                onClick={onUnarchive}
                ml={3}
                disabled={unarchiveEventMutation.isLoading}
              >
                Unarchive
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      {msrEvent && isMoreGroupsOpen && (
        <ImportMsrEvent
          event={event}
          msrEvent={msrEvent}
          isOpen={isMoreGroupsOpen}
          close={() => setIsMoreGroupsOpen(false)}
        />
      )}
    </>
  );
}
