import {
  Badge,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  TableContainer,
  Table,
  Thead,
  Tbody,
  Tr,
  Td,
  Th,
  Text,
} from "@chakra-ui/react";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { api } from "../../api";
import Head from "../../components/Head";
import Footer from "../../components/Footer";
import SettingsSidebar from "../../components/SettingsSidebar";
import {
  Screen,
  TopControls,
  TopLeft,
  TopRight,
  Main,
  MainLeft,
  MainRight,
} from "../../components/template";
import { useLoginOnUnauthorized } from "../../hooks";

function IndexFactorGroups() {
  const handleUnauthorizedError = useLoginOnUnauthorized();

  const { data } = useQuery("index-factor-groups", api.indexFactorGroups, {
    refetchOnMount: true,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    onError: handleUnauthorizedError,
  });

  const groups = data ? data : [];

  return (
    <Screen>
      <Head title="Settings" />
      <TopControls>
        <TopLeft></TopLeft>
        <TopRight>
          <Menu>
            <MenuButton as={Button} colorScheme="green" size="sm">
              + New Group
            </MenuButton>
            <MenuList>
              <MenuItem onClick={() => console.log("new from copy")}>
                New copy from official 2024 PAX
              </MenuItem>
              {/* <MenuItem>New group</MenuItem> */}
            </MenuList>
          </Menu>
        </TopRight>
      </TopControls>
      <Main>
        <MainLeft>
          <SettingsSidebar />
        </MainLeft>
        <MainRight heading="Index Factors (PAX)">
          <TableContainer>
            <Table size="sm" variant="simple">
              <Thead>
                <Tr>
                  <Th>Group</Th>
                  <Th textAlign="center">State</Th>
                  <Th textAlign="center">Linked to</Th>
                  <Th textAlign="right"></Th>
                </Tr>
              </Thead>
              <Tbody>
                {groups.map((g) => (
                  <Tr key={g.id}>
                    <Td>
                      <Link to={`/index-factor-groups/${g.id}`}>
                        <Text as="span" color="blue.500">
                          {g.name}
                        </Text>
                      </Link>
                    </Td>
                    <Td textAlign="center">
                      <Badge>ACTIVE</Badge>
                    </Td>
                    <Td textAlign="center">
                      {g.global_configuration?.key || "-"}
                    </Td>
                    <Td textAlign="right">
                      <Menu>
                        <MenuButton>
                          <strong>...</strong>
                        </MenuButton>
                        <MenuList>
                          <MenuItem onClick={() => console.log("clicked edit")}>
                            Edit
                          </MenuItem>
                          <MenuItem
                            onClick={() => console.log("clicked delete")}
                          >
                            Delete
                          </MenuItem>
                        </MenuList>
                      </Menu>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        </MainRight>
      </Main>
      <Footer />
    </Screen>
  );
}

// IndexFactorGroups.whyDidYouRender = true;

export default IndexFactorGroups;
