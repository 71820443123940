import { useRef, useState } from "react";
import { useMutation, useQuery } from "react-query";
import {
  useToast,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Box,
  Button,
  Flex,
  Heading,
  Stat,
  StatLabel,
  StatNumber,
} from "@chakra-ui/react";
import { DeleteIcon } from "@chakra-ui/icons";
import { useHistory, useParams } from "react-router-dom";
import { api } from "../../api";
import LoadingScreen from "../../components/LoadingScreen";
import EventStatusBadge from "../../components/EventStatusBadge";
import EventDetailFormButton from "./EventDetailFormButton";

function EventDetailSidebar() {
  let params = useParams();
  let { id } = params;
  id ||= "";

  let history = useHistory();
  const toast = useToast();
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const cancelRef = useRef();

  const onDelete = () => deleteEventMutation.mutate();
  const deleteEventMutation = useMutation(() => api.deleteEvent(id), {
    onSuccess: () => {
      setIsDeleteOpen(false);
      history.push("/events");
      toast({
        title: "Success",
        description: "Event successfully deleted",
        status: "success",
        duration: 6000,
        isClosable: true,
      });
    },
    onError: () => {
      toast({
        title: "Error",
        description: "Error deleting event",
        status: "error",
        duration: 6000,
        isClosable: true,
      });
    },
  });

  const {
    isLoading,
    data,
    error,
    refetch: refetchEvent,
  } = useQuery(`event-${id}`, api.event(id), {
    refetchOnMount: true,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });

  const event = data ? data : [];

  if (isLoading) return <LoadingScreen />;

  return (
    <>
      <Flex
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        pt={5}
      >
        <Heading as="h5" size="sm" color="gray.500">
          EVENT DETAILS
        </Heading>
        <EventDetailFormButton
          label="Edit"
          initialEvent={event}
          onMutate={refetchEvent}
        />
      </Flex>
      <Box spacing={0} pt="4">
        <Stat>
          <StatLabel>Status</StatLabel>
          <StatNumber style={{ fontSize: 16 }}>
            <EventStatusBadge state={event.state} />
          </StatNumber>
        </Stat>
        <Stat mt={6}>
          <StatLabel>Date</StatLabel>
          <StatNumber style={{ fontSize: 16 }}>{event.start_date}</StatNumber>
        </Stat>
        <Stat mt={6}>
          <StatLabel>Venue</StatLabel>
          <StatNumber style={{ fontSize: 16 }}>{event.venue}</StatNumber>
        </Stat>
        <Stat mt={6}>
          <StatLabel>Penalty Seconds</StatLabel>
          <StatNumber style={{ fontSize: 16 }}>
            {event.penalty_seconds}
          </StatNumber>
        </Stat>
        <Stat mt={6}>
          <StatLabel>Series</StatLabel>
          <StatNumber style={{ fontSize: 16 }}>
            {event?.series[0]?.name}
          </StatNumber>
        </Stat>
      </Box>
      <Button
        leftIcon={<DeleteIcon />}
        onClick={() => setIsDeleteOpen(true)}
        colorScheme="red"
        width="100%"
        mt={40}
        mr={4}
        variant="outline"
      >
        Delete
      </Button>
      <AlertDialog
        isOpen={isDeleteOpen}
        leastDestructiveRef={cancelRef}
        onClose={() => setIsDeleteOpen(false)}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete event - {event.name}
            </AlertDialogHeader>
            <AlertDialogBody>
              Are you sure?{" "}
              <strong>
                This will delete all event data including run times
              </strong>{" "}
              You cannot undo this action.
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={() => setIsDeleteOpen(false)}>
                Cancel
              </Button>
              <Button colorScheme="red" onClick={onDelete} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
}

export default EventDetailSidebar;
