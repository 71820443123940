import { useRef, useState } from "react";
import { useQuery } from "react-query";
import {
  Input,
  FormErrorMessage,
  Button,
  Select,
  FormControl,
  FormLabel,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Heading,
  Progress,
} from "@chakra-ui/react";
import { api } from "../../api";

export default function IndexFactorGroupSelect({ close, onFinish }) {
  const selectRef = useRef();
  const [penaltySeconds, setPenaltySeconds] = useState(2);

  const { isLoading, data, error } = useQuery(
    "index-factor-groups",
    api.indexFactorGroups,
    {
      refetchOnMount: true,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );

  const handleNext = () => {
    onFinish(selectRef.current.value, penaltySeconds);
  };

  const handlePenaltySecondsChange = (e) => {
    setPenaltySeconds(e.target.value);
  };

  const groups = data ? data : [];

  return (
    <ModalContent>
      <ModalHeader>Import MSR event</ModalHeader>

      {!isLoading && (
        <>
          <ModalBody>
            <FormControl>
              <FormLabel>Select a PAX configuration:</FormLabel>
              <Select ref={selectRef} placeholder="Select a PAX configuration">
                {groups.map((s) => (
                  <option key={`groups-${s.id}`} value={s.id}>
                    {s.name}
                  </option>
                ))}
              </Select>
            </FormControl>
            <FormControl
              // isInvalid={
              //   !Number.isInteger(Number(selectRef.current?.value)) ||
              //   Number(selectRef.current?.value) < 0
              // }
              mb={6}
            >
              <FormLabel htmlFor="penalty_seconds">Penalty Seconds</FormLabel>
              <Input
                defaultValue={2}
                id="penalty_seconds"
                placeholder="penalty seconds"
                autoComplete="off"
                isRequired
                onChange={handlePenaltySecondsChange}
              />
              {/* <FormErrorMessage>{form.errors.penalty_seconds}</FormErrorMessage> */}
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button onClick={close} ml={3}>
              Close
            </Button>
            <Button colorScheme="blue" onClick={handleNext} ml={3}>
              Next
            </Button>
          </ModalFooter>
        </>
      )}
      {isLoading && (
        <ModalBody>
          {/* <Heading size="sm" pb={4}>
            Importing segments from MSR...
          </Heading> */}
          <Progress size="xs" isIndeterminate my={8} />
        </ModalBody>
      )}
      {error && (
        <ModalBody>
          <Heading size="sm" pb={4} color="red.500">
            Unable to fetch PAX configurations. Please try again in a minute.
          </Heading>
        </ModalBody>
      )}
    </ModalContent>
  );
}
